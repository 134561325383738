import axiosInstance from '../_helpers/axiosInstance';

export const quoteService = {
    postLoadForQuoting,
    postLoadForIndividualQuoting,
    getQuotes,
    getWinningQuote,
    awardQuote,
    revokeWinningQuote,
    getQuoteInfoForPdf,
    getExchangeRates,
    sendShippingInstructionsEmail
};

const API_URL = process.env.REACT_APP_API_ENDPOINT;

function postLoadForQuoting(data){
    return axiosInstance({
        method: 'POST',
        url:`${API_URL}/quote/PostLoadForQuoting`,
        data: {
            LoadId: data.loadId,
            QuoteRequiredBy: data.quoteDateTime,
            CommentsForVendors: data.quoteComments
        }
    });
}

function postLoadForIndividualQuoting(data){
    return axiosInstance({
        method: 'POST',
        url:`${API_URL}/quote/PostLoadForIndividualQuoting`,
        data: {
            LoadId: data.loadId,
            Email: data.email,
            QuoteRequiredBy: data.quoteDateTime,
            CommentForVendor: data.quoteComments,
        }
    });
}

function getQuotes(loadId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/quote?LoadId=${loadId}`,
    });
}

function getWinningQuote(loadId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/quote/GetWinningQuote?LoadId=${loadId}`,
    });
}

function awardQuote(loadId, quoteId){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/quote/Award`,
        data: {
            LoadId: loadId,
            QuoteId: quoteId,
        }
    });
}

function revokeWinningQuote(loadId, quoteId){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/quote/Revoke`,
        data: {
            LoadId: loadId,
            QuoteId: quoteId,
        }
    });
}

function getQuoteInfoForPdf(loadId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/quote/GetQuoteInfoForPdf?LoadId=${loadId}`,
    });
}

function getExchangeRates(){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/quote/GetExchangeRates`,
    });
}

function sendShippingInstructionsEmail(pdfBlob, vendorEmail, loadId){
    var token = localStorage.getItem('jwtBearer').replace(/^"(.*)"$/, '$1');

    var bodyFormData = new FormData();
    bodyFormData.append('ShippingInstructions', pdfBlob);
    bodyFormData.append('VendorEmail', vendorEmail);
    bodyFormData.append('LoadId', loadId);

    const requestOptions = {
        method: 'POST',
        body: bodyFormData,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    return fetch(`${API_URL}/quote/SendShippingInstructionsEmail`, requestOptions);
}