import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from '../../_actions';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import StockTruck from '../../_resources/stock-truck.png';
import Warehouse from '../../_resources/warehouse.jpg';
import HighwayTruck from '../../_resources/truck-on-highway.jpg';
import PeopleWarehouse from '../../_resources/people-warehouse-clipboard.jpg';
import WarehouseWorkers from '../../_resources/warehouse-workers.jpeg';
import Footer from '../../components/Footer';

const classes = {
	firstContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden'
	},
    loginButton: {
		backgroundColor: '#308cdc',
		marginLeft: 2,
		paddingLeft: 3,
		paddingRight:3,
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15
    },
	signUpButton: {
		backgroundColor: '#20ac9c',
		paddingLeft: 3,
		paddingRight:3,
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15
    },
	buttonBox: {
		float: 'right',
		margin: 3
	},
	slogan: {
		fontWeight: 'bold',
		fontSize: 18
	},
	secondContainer: {
		paddingTop: 5,
		paddingBottom: 5
	},
	thirdContainer: {
		paddingTop: 5,
		paddingBottom: 5,
		backgroundColor: '#f2f5f7',
	}
};

function LandingPage() {
	const dispatch = useDispatch();

	// Reset login status
	useEffect(() => { 
		document.title = "DFQ";
		dispatch(userActions.logout()); 
	}, []);

	return (
		<>
		<CssBaseline />
		<Container maxWidth={false} disableGutters sx={classes.firstContainer}>
			<Box sx={classes.buttonBox}>
				<Link to='/signup'><Button variant="contained" sx={classes.signUpButton}>Sign Up</Button></Link>
				<Link to='/login'><Button variant="contained" sx={classes.loginButton}>Log In</Button></Link>
			</Box>
			<Grid
				container
				sx={{ minHeight: '60vh', paddingTop: '5%' }}
			>
				<Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center' }}>
					<Box sx={{ textAlign: 'left' }}>
						<Typography variant='h1' sx={{color: '#1EBBD7'}}>Quoting,</Typography>
						<Typography variant='h1' sx={{color: '#1EBBD7'}}>Simplified.</Typography>
						<Typography variant='h5'>A simple and efficient quoting system </Typography>
						<Typography variant='h5'>so you can focus on what you do best</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} sm={6} >
					<img src={StockTruck} height={400} alt='stock-truck'/>
				</Grid>
			</Grid>
		</Container>
		<Container sx={classes.secondContainer}>
			<Typography variant='h3' sx={{textAlign: 'center', fontWeight: 'bold'}}>Your End-To-End Logistics Partner</Typography>
			<Grid container spacing={2} sx={{marginTop: 5, textAlign: 'center'}}>
				<Grid item xs={12} sm={4} sx={{ textAlign: 'left' }}>
					<img src={Warehouse} height={300} width={300} alt='warehouse'/>
					<Typography variant='h4'>Loads</Typography>
					<Typography>Integrate our digital platform to post loads for vendors to see. We will help you keep track of your pickups, deliveries, shipping costs, and much more. Posting a load can be done in minutes!</Typography>
				</Grid>
				<Grid item xs={12} sm={4} sx={{ textAlign: 'left' }}>
					<img src={HighwayTruck} height={300} width={300} alt='truck'/>
					<Typography variant='h4'>Quotes</Typography>
					<Typography>Our software allows you to send your load for quoting to potentially hundreds of vendors across North America. You get to choose which vendor is the best fit for you!</Typography>
				</Grid>
				<Grid item xs={12} sm={4} sx={{ textAlign: 'left' }}>
					<img src={PeopleWarehouse} height={300} width={300} alt='people'/>
					<Typography variant='h4'>Business</Typography>
					<Typography>Make your job easier, cutting down operating costs, and boosting profits. We help to drive your business forward by utilizing the latest technology and software on the market!</Typography>
				</Grid>
			</Grid>
		</Container>
		<Container maxWidth={false} disableGutters sx={classes.thirdContainer}>
			<Container>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<img src={WarehouseWorkers} alt='warehouse-workers'/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Typography variant='h3' sx={{fontWeight: 'bold', marginTop: 3}}>Why Choose DFQ</Typography>
						<Box sx={{marginTop: 5, paddingLeft: 3, borderLeft: '3px solid #58AFC3'}}>
							<Typography variant='h4'>Innovative Tech</Typography>
							<Typography variant='body'>Increase your revenues, improve your customer experience and reduce costs with our industry-leading software powered by our; real-time quote tracker, innovative shipping solutions, and load management system.</Typography>
						</Box>
						<Box sx={{marginTop: 5, paddingLeft: 3, borderLeft: '3px solid #58AFC3'}}>
							<Typography variant='h4'>Simplify Your Logistics</Typography>
							<Typography variant='body'>Accelerate order fulfillment, reduce delivery time, improve returns management, and enhance shopper support, all with the same partner.</Typography>
						</Box>
						<Box sx={{marginTop: 5, paddingLeft: 3, borderLeft: '3px solid #58AFC3'}}>
							<Typography variant='h4'>Customer Support</Typography>
							<Typography variant='body'>We provide prompt responses, and personalized assistance for each client. We ensure that our support team is well-trained, knowledgeable, and equipped to handle any issues efficiently.</Typography>
						</Box>
						<Box sx={{marginTop: 5, paddingLeft: 3, borderLeft: '3px solid #58AFC3'}}>
							<Typography variant='h4'>Increase Revenue</Typography>
							<Typography variant='body'>We provide innovative solutions that streamline your operations and enhance customer experiences. By leveraging cutting-edge technologies and optimizing workflows, we help reduce costs and improve efficiency, driving growth and profitability for your business.</Typography>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Container>
		{/* <Footer /> */}
		</>
	);
}

export { LandingPage };